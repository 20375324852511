<template>
  <div class="wh-container">
    <div class="header">
      <p class="title">
        Exames/procedimentos
        <span>
          {{ `${count || 'Nenhum'} paciente${count > 1 ? 's' : ''}` }}
          aguardando
        </span>
      </p>
    </div>
    <div class="body">

    <div class="d-flex flex-row justify-content-between">
        <InputSearch
          autocomplete="off"
          class="placeholder-color mr-2"
          id="search"
          debounce="300"
          placeholder="Pesquisar por paciente ou prontuário"
          v-model="query"
        />
        
      <div class="d-flex flex-row">

      <b-button
        class="filter-button mb-2 mr-2"
        variant="primary"
        @click="getAppointmentExams"
      >
        Aplicar
      </b-button>
      <b-button
        class="filter-button mb-2"
        variant="outline-primary"
        v-b-toggle.exams-sidebar
      >
        <FilterIcon class="mr-2"/>
        Filtros
        <div class="ellipse-applied-filter" v-if="!isFilterDefault"/>
      </b-button>
    </div>
    </div>
    <b-sidebar 
      id="exams-sidebar"
      bg-variant="white"
      no-header
      right
      shadow  
    >
      <div
        class="column exams-sidebar__body"
      >
      <div
            class="d-flex mx-2 flex-row align-items-center justify-content-between mb-3"
          >
            <div class="sidebar-header">Filtros</div>
            <CloseIcon class="icon" v-b-toggle.exams-sidebar />
          </div>

      <b-col>
        <b-form-group>
          <label for="search">Paciente</label>
          <InputSearch
            autocomplete="off"
            class="placeholder-color"
            id="search"
            debounce="300"
            placeholder="Pesquisar por paciente ou prontuário"
            v-model="query"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <label for="multiselect-priority">Prioridade</label>
          <MultiSelectPriority
            placeholder="Prioridades"
            v-model="priorityLevel"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">Situações</label>
          <CheckboxSelect
            id="appointmentSituation"
            trackBy="value"
            optionLabel="label"
            v-model="appointmentStatus"
            :options="appointmentStatusOptions"
            :value="[]"
            placeholder="Todas as situações"
            v-b-tooltip.html.hover.right="tooltipContent('appointmentSituation')"
          />
            <!-- :clearSelection="shouldReload" -->
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">Salas</label>
          <CheckboxSelect
            id="clinicRooms"
            trackBy="value"
            optionLabel="label"
            v-model="roomIds"
            :options="clinicRoomsOptions"
            :value="[]"
            placeholder="Todas as salas"
            v-b-tooltip.html.hover.right="tooltipContent('clinicRooms')"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">Exames/procedimentos</label>
          <CheckboxSelect
            id="examStatus"
            trackBy="value"
            optionLabel="label"
            v-model="examStatus"
            :options="examStatusOptions"
            :value="[]"
            placeholder="Todos os exames/procedimentos"
            v-b-tooltip.html.hover.right="tooltipContent('examStatus')"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">Profissionais</label>
          <CheckboxSelect
            id="professionals"
            trackBy="value"
            optionLabel="label"
            v-model="professionalIds"
            :options="professionalOptions"
            :value="[]"
            placeholder="Todos os Profissionais"
            v-b-tooltip.html.hover.right="tooltipContent('professionals')"
          />
        </b-form-group>
      </b-col>
      <div class="d-flex flex-column justify-content-center align-items-center mx-2">
        <b-button
          class="filter-button mb-2"
          variant="primary"
          @click="getAppointmentExams"
          v-b-toggle.exams-sidebar
        >
          Confirmar
        </b-button>

        <b-button
          class="filter-button"
          variant="outline-primary"
          @click="resetFilters"
          v-if="!isFilterDefault"
        >
          Redefinir
        </b-button>
      </div>
      
    </div>

    </b-sidebar>



    <section class="table-data">
      <Tab
        ref="exams_tabs"
        :index="currentTabIndex"
        v-bind:keys="tabsFiltered"
        v-on:onChange="onChangeTab"
        id="exams-tabs"
        class="exams__tabs"
      >

      </Tab>
      <ExamsTable
        v-if="!isLoading && tabsFiltered[currentTabIndex] === 'Atendimentos'"
        :examsList="examsList"
        @resetAttendance="getAppointmentExams"
      />

      <LabpacsTable
        v-if="!isLoading && tabsFiltered[currentTabIndex] === 'Labpacs'"
        :examsList="examsList"
        @resetAttendance="getAppointmentExams"
        @reload="getAppointmentExams"
        v-can="'LaudoMedLab1'"
      />

      <EssilorTable
        v-if="!isLoading && tabsFiltered[currentTabIndex] === 'Essilor'"
        :examsList="examsList"
        @reload="getAppointmentExams"
        v-can="'LaudoMedLab1'"
      />

      <div class="skeleton" v-if="isLoading">
        <b-skeleton v-for="n in 5" :key="n" class="mb-2" />
      </div>

      <div class="pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
    </section>


  </div>

  </div>
</template>

<script>
import { statusOptions } from '@/utils/appointmentHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { showFeature, hasPermissionLabpacsExams } from '@/utils/permissionsHelp'

export default {
  name: 'Exams',
  metaInfo: {
    title: 'Eyecare - Exame'
  },
  components: {
    ExamsTable: () => import('@/components/Exam/ExamsTable'),
    LabpacsTable: () => import('@/components/Exam/LabpacsTable'),
    EssilorTable: () => import('@/components/Exam/EssilorTable'),
    CheckboxSelect: () => import('@/components/CheckboxSelect.vue'),
    InputSearch: () => import('@/components/General/InputSearch'),
    MultiSelectPriority: () =>
      import('@/modules/schedule/components/MultiSelectPriority'),
    FilterIcon: () => import('@/assets/icons/filter.svg'),
    CloseIcon: () => import('@/assets/icons/close.svg'),
    Tab: () => import('@/components/TabPills'),
    
  },
  async created() {
    await this.getAppointmentExams()
    await this.getClinicRooms()
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      clinicRooms: [],
      clinicRoomsOptions: [],
      appointmentStatusOptions: this.getStatusOptions(),
      examStatusOptions: [
        { label: 'Pendentes', value: 'PENDING' },
        { label: 'Realizados', value: 'FINISHED' },
        { label: 'Pausados', value: 'PAUSED' }
      ],
      examsList: [],
      professionalOptions: [],
      query: null,
      roomIds: [],
      professionalIds: [],
      appointmentStatus: [],
      examStatus: [],
      priorityLevel: [],
      page: 1,
      count: 0,
      limit: 0,
      isLoading: false,
      currentTabIndex: 0,
      tabs: [
        { label: 'Atendimentos', value: 'Atendimentos', feature: null },
        { label: 'Labpacs', value: 'Labpacs', feature: 'Integração com Labpacs' },
        { label: 'Essilor', value: 'Essilor', feature: 'Integração com Essilor' }
      ],
      defaultFilters: {
        query: null,
        appointmentStatus: [],
        roomIds: [],
        examStatus: [],
        professionalIds: [],
        priorityLevel: []
      },
    }
  },
  computed: {
    isFilterDefault() {
      return !this.query && !this.appointmentStatus && !this.roomIds && !this.examStatus && !this.professionalIds && !this.priorityLevel.length
    },
    hasLabpacsIntegrationFeature() {
			return this.showFeature('Integração com Labpacs') && hasPermissionLabpacsExams()
		},
    tabsFiltered() {
      return this.tabs.filter(tab => !tab.feature || this.showFeature(tab.feature)).map(tab => tab.label)
    }
  },  
  methods: {
    showFeature,
    async getClinicRooms() {
      try {
        const { data } = await this.api.getClinicRooms(this.clinic.id)
        this.clinicRooms = data.data
        this.clinicRoomsOptions = this.clinicRooms.map(room => ({
          label: room.name,
          value: room.id
        }))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    getApiParams() {
      return {
        clinic_id: this.clinic.id,
        page: this.page,
        query: this.query,
        appointment_status: this.appointmentStatus?.map(({ value }) => value),
        room_ids: this.roomIds?.map(({ value }) => value),
        status: this.examStatus?.map(({ value }) => value),
        professional_ids: this.professionalIds?.map(({ value }) => value),
        priority_level: this.priorityLevel?.map(el => el.value)
      }
    },
    async getAppointmentExams() {
      this.isLoading = true
      try {
        const params = this.getApiParams()

        const { data } = await this.api.getAppointmentExams(params)
        this.examsList = data.data
        this.count = data.total
        this.limit = data.per_page
        this.page = data.current_page

        this.getProfessionals();
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async getProfessionals(query) {
      try {
        const { data } = await this.api.getProfessionals(
          1,
          this.clinic.id,
          query,
          ['DOCTOR', 'DOCTOR_MANAGER']
        )
        const options = data.data.map(prof => ({
          label: prof.name,
          value: prof.id
        }))
        this.professionalOptions = options
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    onChangeTab(index) {
      this.currentTabIndex = index
    },
    resetFilters() {
      this.query = null
      this.appointmentStatus = []
      this.roomIds = []
      this.examStatus = []
      this.professionalIds = []
      this.priorityLevel = []

      this.getAppointmentExams()
    },
    tooltipContent(input){
      let options = '';
      switch(input){
        case 'appointmentSituation': 
          this.appointmentStatus.forEach((el, index) => {
            if(index !== this.appointmentStatus.length - 1){
              options += `${el.label}<br>` 
            } else {
              options += el.label;
            }
          })
          break;
        case 'clinicRooms': 
          this.roomIds.forEach((el, index) => {
            if(index !== this.roomIds.length - 1){
              options += `${el.label}<br>` 
            } else {
              options += el.label;
            }
          })
          break;
        case 'examStatus': 
          this.examStatus.forEach((el, index) => {
            if(index !== this.examStatus.length - 1){
              options += `${el.label}<br>` 
            } else {
              options += el.label;
            }
          })
          break;
        case 'professionals': 
          this.professionalIds.forEach((el, index) => {
            if(index !== this.professionalIds.length - 1){
              options += `${el.label}<br>` 
            } else {
              options += el.label;
            }
          })
          break;
        default:
          options = '';
          break;
      }
      return options;
    },
    getStatusOptions(){
      const appointmentStatus = statusOptions
      appointmentStatus.shift()
      return appointmentStatus
    }
  },
  watch: {
    page: {
      handler: async function () {
        await this.getAppointmentExams()
      }
    },
    async query() {
      await this.getAppointmentExams()
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .body {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
  }


  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);

    span {
      font-size: 12px;
      line-height: 130%;
      font-weight: 600;
      color: var(--type-active);
    }
  }

  .exams-sidebar__body {
  padding: 5px;

  .sidebar-header {
    padding: 10px 0;
    font-family: Red Hat Display;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #0c1d59;
  }

  label {
    font-weight: 400;
  }
}

  .filter-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

  }

  .ellipse-applied-filter {

    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 50%;

    /* Orange */
    background: #FF6B00;

    right: -22px;
    top: -16px;

  }


  .b-skeleton {
    height: 3vh;
  }


  .icon {
    width: 24px;
    height: 24px;
   }
  .table-data {
    background-color: #ffffff !important;
    border-radius: 8px
  }
}
</style>
